@import "../App.scss";

.phone-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;

  .phone-input-container {
    width: 90%;
    margin-right: 8px;

    .phone-select,
    .phone-input {
      margin: 4px 0px;
    }
  }
}
