@import "../App.scss";

.footer {
  text-align: center;
}

@media screen and (max-width: $responsive) {
  .footer {
    padding-bottom: 120px;
  }
}
