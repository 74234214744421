@import "../App.scss";

.platform-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;

  .platform-input-container {
    width: 90%;
    margin-right: 8px;
  }
  .responsive-select-display {
    display: none;
  }

  .platform-checkbox {
    .ant-checkbox {
      display: none;
    }
  }
}

@media screen and (max-width: $responsive) {
  .platform-inputs {
    .ant-input-group-addon:has(.ant-select) {
      display: none;
    }

    .ant-input {
      &.ant-input-outlined {
        border-radius: 6px 0px 0px 6px !important;
      }
    }
    .responsive-select-display {
      display: flex;
      margin: 8px 0px;
      width: 100%;

      .ant-select {
        width: 100%;
      }
    }
  }
}
