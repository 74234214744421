@import "../App.scss";

.add-submenu {
  .menu-button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 8px 16px;
    color: white;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .ant-menu-item {
    margin: 0;
    padding: 0;
  }
}

.navbar-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: $responsive) {
  .navbar-desktop {
    display: none;
  }
}
