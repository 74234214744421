@import "../App.scss";

.custom-relationship {
  .custom-relationship-list {
    .custom-relationship-item {
      .ant-tag {
        margin: 8px;
      }
      .arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
    }
  }
}
