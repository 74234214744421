@import "../App.scss";

.task-form {
  .favorite-checkbox {
    .ant-row {
      &.ant-form-item-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .ant-col.ant-form-item-label {
          padding: 0;
        }

        .ant-col.ant-form-item-control {
          width: fit-content;
        }
      }
    }

    .platform-checkbox {
      .ant-checkbox {
        display: none;
      }
    }
  }
}
