@import "../App.scss";

@media screen and (max-width: $responsive) {
  .tasks-list {
    .ant-tag {
      margin: 8px 4px;
      padding: 4px 8px;
      border: $border;
      width: 100%;
      text-align: center;

      &.ant-tag-checkable-checked {
        border-color: $primary-color;
      }
    }
  }
}
