@import "../App.scss";

.email-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;

  .email-input-container {
    width: 90%;
    margin-right: 8px;
  }

  .responsive-select-display {
    display: none;
  }
}

@media screen and (max-width: $responsive) {
  .email-inputs {
    .ant-input-group-addon {
      display: none;
    }
    .ant-input {
      &.ant-input-outlined {
        border-radius: 6px;
      }
    }

    .responsive-select-display {
      display: flex;
      width: 100%;
      margin: 8px 0px;

      .email-select {
        width: 100%;
      }
    }
  }
}
