@import "../App.scss";
$spacing: 4px;

.below-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  p {
    padding: 0px 8px;
  }
}

.person-page,
.person-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .sphere-card {
    width: 100%;
    margin: 8px $spacing;

    .sphere-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      a {
        margin: 8px;
      }
    }
  }

  .card-container {
    min-height: 80vh;
    min-width: 300px;
    margin: $spacing;
    width: 44%;

    .card-children {
      margin: 8px 0px;
    }
  }

  .column-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 55%;
    padding: 0px 4px;

    .card-container {
      min-height: auto;
      width: 100%;

      &.relationship-card {
        .relationship-list {
          list-style: none;
          margin: 0;
          padding: 0;

          .relationship-item {
            margin: 8px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $responsive) {
  .person-page,
  .person-form {
    .card-container {
      width: 100%;
    }

    .column-right {
      width: 100%;
    }
  }
}
