@import "../App.scss";

.list-page-btn-header {
  margin: 16px 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.responsive-only {
    display: none;
  }
}

.list-page-table {
  margin: 4px;
}

.responsive-list {
  display: none;
}

.ant-dropdown {
  width: 400px;
  .ant-table-filter-dropdown {
    padding: 24px;
  }
}

@media screen and (max-width: $responsive) {
  .page-title-header {
    margin: 8px 0;
  }

  .list-page-btn-header {
    margin: 16px 0px;
    padding: 0px;
    justify-content: space-between;

    button {
      margin: 0;
    }

    &.responsive-only {
      display: flex;
    }

    &.desktop-only {
      display: none;
    }
  }

  .list-page-table {
    display: none;
  }

  .responsive-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;

    li {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    .li-container {
      width: 100%;
      margin: 8px 0px;

      .card {
        width: 100%;
        padding: 0px;
        margin: 0px;

        .ant-card-body {
          padding: 0px 24px;
        }

        .sphere-list {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin: 0px;
          padding: 0px;

          .sphere-li {
            margin: 8px 4px;
          }
        }
      }
    }
  }
}
