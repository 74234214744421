@import "../App.scss";

.calendar-container {
  .calendar-events {
    .favorite-task {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.ant-spin-container {
  margin-bottom: 24px;

  .ant-list-item.task-item {
    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
    }

    .favorite-task {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

@media screen and (max-width: $responsive) {
  .calendar-container {
    .calendar-events {
      .favorite-task,
      .other-task {
        display: none;
      }
    }
  }

  .ant-picker-cell:has(li) {
    background-color: #bae0ff;
  }
}
