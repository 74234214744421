@import "../App.scss";

.copy-csv-format {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 0px;

  .confirm-text {
    padding: 0px 8px;
  }
}
