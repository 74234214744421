@import "../App.scss";

.bottom-nav-menu {
  display: none;
}

@media screen and (max-width: $responsive) {
  .bottom-navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #001529;
    z-index: 1000;

    .ant-menu-horizontal {
      line-height: 0px;
    }

    .bottom-nav-menu {
      display: flex;
      justify-content: center;

      .ant-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
      }

      .ant-menu-item-icon {
        font-size: 20px;
      }

      .ant-menu-submenu,
      .ant-menu-title-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-menu-submenu-title {
          font-size: 20px;
        }
      }
    }
  }

  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark {
    position: fixed !important;
    // bottom: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    padding: 0% !important;
    margin-bottom: 40px !important;

    .ant-menu.ant-menu-sub.ant-menu-vertical {
      // max-height: 60vh !important;
      overflow-y: scroll;
      min-width: 80vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
