@import "../App.scss";

.task-card {
  .favorite-checkbox {
    .ant-row {
      &.ant-form-item-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .ant-col.ant-form-item-label {
          padding: 0;
        }

        .ant-col.ant-form-item-control {
          width: fit-content;
        }
      }
    }

    .platform-checkbox {
      .ant-checkbox {
        display: none;
      }
    }
  }

  .favorite-task {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (max-width: $responsive) {
  .task-list {
    .text {
      margin-left: 4px;
    }
    .ant-tag {
      margin: 8px 4px;
      padding: 4px 8px;
      border: $border;

      &.ant-tag-checkable-checked {
        border-color: $primary-color;
      }
    }
  }
}
