@import "../App.scss";
.responsive-list-container {
  display: none;
}
@media screen and (max-width: $responsive) {
  .responsive-list-container {
    display: block;
  }
  .filter-modal {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }

    .checkbox-container {
      padding: 8px 0px;
      &.select-all {
        border-bottom: $border;
      }
    }

    .ant-checkbox-wrapper {
      .ant-checkbox {
        display: none;
      }

      &.filter-checkbox {
        background-color: transparent;
        border: $border;
        border-radius: $border-radius;
        margin: 4px;
        padding: 4px 8px;

        &.checked {
          background-color: $primary-color;
          color: white;
          border-color: $primary-color;
        }
      }
    }
  }
}
