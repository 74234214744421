@import "../App.scss";

.dashboard {
  .dashboard-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px 0;

    .card {
      &.tasks-list {
        width: 50%;

        .ant-card-body {
          padding: 0;
        }

        .task-item {
          padding: 16px 24px;

          &:hover {
            cursor: pointer;
            background-color: #f5f5f5;
          }

          .favorite-task {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      &.chart {
        width: 49%;
      }
    }
  }
}

@media screen and (max-width: $responsive) {
  .dashboard {
    .dashboard-content {
      flex-direction: column-reverse;

      .card.tasks-list,
      .card.chart {
        width: 100%;
      }
    }
  }
}
