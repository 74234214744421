$first-color: #00b4d8;
$first-color-bg: #ecf9fc;
$second-color: #023e8a;
$second-color-bg: #dfedff;
$third-color: #38b000;
$third-color-bg: #ddfdcf;
$fourth-color: #d90429;
$fourth-color-bg: #ffe9ed;
$responsive: 900px;
$border: 1px solid #d9d9d9;
$border-radius: 4px;
$primary-color: #1677ff;

.tag {
  border: 1.5px solid;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &.first {
    color: $first-color;
    background-color: $first-color-bg;
  }
  &.second {
    color: $second-color;
    background-color: $second-color-bg;
  }
  &.third {
    color: $third-color;
    background-color: $third-color-bg;
  }
  &.fourth {
    color: $fourth-color;
    background-color: $fourth-color-bg;
  }
}

.first {
  color: $first-color;
}
.second {
  color: $second-color;
}
.third {
  color: $third-color;
}
.fourth {
  color: $fourth-color;
}

.header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .logo {
    height: 64px;
  }

  .title {
    font-weight: bold;
    margin-right: 32px;
  }
}

.page-title-header {
  width: 100%;
  background: linear-gradient(
    145deg,
    rgba(24, 144, 255, 1) 16%,
    rgba(9, 109, 217, 1) 40%,
    rgba(11, 114, 222, 0.9192051820728291) 72%,
    rgba(24, 144, 255, 0.3981967787114846) 96%
  );
  color: white;
  padding: 16px 24px;
  margin: 8px 0px;
  border-radius: $border-radius;

  &.secondary {
    background: linear-gradient(
      145deg,
      rgba(144, 144, 144, 1) 16%,
      rgba(109, 109, 109, 1) 40%,
      rgba(114, 114, 114, 0.9192051820728291) 72%,
      rgba(144, 144, 144, 0.3981967787114846) 96%
    );
  }
}

.ant-layout .ant-layout-sider-zero-width-trigger {
  top: 0 !important;
}

.ant-table-row {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header {
    .logo {
      height: 40px;
    }

    .logout {
      display: none;
    }
  }
}
